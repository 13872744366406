import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from '@vex/services/cookie.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionDialogComponent } from '@vex/components/session-dialog/session-dialog.component';
import { HttpCancelService } from '@vex/http-interceptor/http-cancel.service';
import { Toast, ToastrService } from 'ngx-toastr';
import { HelperService } from '@vex/services/helper.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    baseUrl = environment.API_URL;
    errorData: {};
    userActivity: any;
    dialogRef: any;
    idleTime: number = 0;
    isShowExpiryMessage: boolean = false;
    isLoginPage: boolean = true;
    user: any = {};

    constructor(
        private http: HttpClient,
        public cookie: CookieService,
        private router: Router,
        private dialog: MatDialog,
        private httpCancelService: HttpCancelService,
        private toastrService: ToastrService,
        private helperService: HelperService
    ) {
        this.checkLastActivity();
    }

    redirectUrl: string;

    checkLastActivity() {
        if(!this.router.url.includes('forgot-password') && !this.router.url.includes('reset-password')) {
            this.isLoginPage = this.router.url.includes('login');
            if(!sessionStorage.getItem('ActiveTab') && (!localStorage.getItem('ActiveTab') || localStorage.getItem('ActiveTab') == '0')){
                this.logout(false);
            } else if(!this.isLoginPage){
                let activeTab = localStorage.getItem('ActiveTab') || 0;
                activeTab = Number(activeTab);
                localStorage.setItem('ActiveTab', (activeTab + 1).toString());
            }
            let changeProcess = sessionStorage.getItem('ChangeProcessData');
            if (changeProcess) {
                this.helperService.changeRequestData = JSON.parse(changeProcess);
                sessionStorage.removeItem('ChangeProcessData');
            }
        }
    }

    loginCredentials(user: any) {
        return this.SendLoginRequest(user);
    }

    private SendLoginRequest(userParams: any) {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };

        return this.http.post(this.baseUrl + 'Account/Login', userParams, options).pipe(
            map((user: any) => {
                if (user) {
                    let rights: any = {};
                    rights['FeatureIds'] = [];
                    rights['ActionIds'] = [];
                    user.RoleArray.forEach((role) => {
                        if (role.RoleAuthRules) {
                            role.RoleAuthRules.forEach((access) => {
                                if (!rights['FeatureIds'].find((f) => f == access.FeatureId)) {
                                    if (access.PageAccess)
                                        rights['FeatureIds'].push(access.FeatureId);
                                }
                                if (access.ActionIds) {
                                    access.ActionIds.forEach((action) => {
                                        if (!rights['ActionIds'].find((a) => a == action))
                                            rights['ActionIds'].push(action);
                                    });
                                }
                            });
                        }
                    });
                    user['UserRights'] = rights;
                    localStorage.setItem('ReviewerRoles', user['ReviewerRoles']);
                    localStorage.setItem('ModifyInstanceRoles', user['ModifyInstanceRoles']);
                    localStorage.setItem('token', user['Token']);
                    localStorage.setItem('token', user['Token']);
                    localStorage.setItem('IdleTime', new Date().getTime().toString());
                    localStorage.setItem('SessionTime', (user['SessionTime'] ? Number(user['SessionTime']) : 900).toString());
                    localStorage.setItem('Info', JSON.stringify(user));
                    localStorage.setItem('IsRefreshTokenCall', 'false');
                    let activeTab = localStorage.getItem('ActiveTab') || 0;
                    activeTab = Number(activeTab);
                    localStorage.setItem('ActiveTab', (activeTab + 1).toString());
                    this.storeRefreshToken(user['RefreshToken']);
                    delete user['RefreshToken'];
                    delete user['Token'];
                    this.isShowExpiryMessage = true;
                    this.user = user;
                }
            }),
            catchError(this.handleError)
        );
    }

    storeRefreshToken(refreshToken) {
        // this.deleteCookie();
        var expires = new Date(Date.now() + Number(localStorage.getItem('SessionTime')) * 2 * 1000).toUTCString();
        this.cookie.setCookie({
            name: '_Kumorai-RefreshToken',
            value: refreshToken,
            expireDate: expires
        });
        expires = new Date(Date.now() + Number(localStorage.getItem('SessionTime')) * 1000).toUTCString();
        this.cookie.setCookie({
            name: '_Kumorai-ExpireToken',
            value: expires,
            expireDate: expires
        });
        expires = new Date(Date.now() + 840 * 1000).toUTCString();
        this.cookie.setCookie({
            name: '_Kumorai-ExpireNewToken',
            value: expires,
            expireDate: expires
        });
    }

    getRefreshToken(): Observable<any> {
        let refreshToken = this.cookie.getCookie('_Kumorai-RefreshToken');
        return this.http.post<any>(this.baseUrl + 'Account/RefreshToken' + '', {
            Token: refreshToken
        });
    }

    revokeToken(refreshToken): Observable<any> {
        return this.http.post<any>(this.baseUrl + 'Account/RevokeToken' + '', {
            Token: refreshToken
        });
    }

    isLoggedIn() {
        let refreshToken = this.cookie.getCookie('_Kumorai-RefreshToken');
        if (localStorage.getItem('token') != '' && this.getUserInfo() && refreshToken) {
            return true;
        }
        if (this.router.url.includes('register')) {
            return true;
        }
        this.httpCancelService.onCancelPendingRequests();
        if (this.dialogRef) this.dialogRef.close(false);
        if (this.isShowExpiryMessage) {
            this.toastrService.error('Please Login Again', 'Session Expired');
            this.logout();
        } else {
            sessionStorage.removeItem('ActiveTab');
            this.router.navigate(['login'], { queryParams: { returnUrl: this.redirectUrl } });
        }
        return false;
    }

    getRights(type, id) {
        let isAllow: boolean = false;
        const info = JSON.parse(localStorage.getItem('Info'));
        if (info && info.UserRights[type].find((x) => x == id)) isAllow = true;
        return isAllow;
    }

    getUserInfo() {
        const currentUser = JSON.parse(localStorage.getItem('Info'));
        return currentUser;
    }

    getUserProperty(name: string, type: string = '') {
        const currentUser = JSON.parse(localStorage.getItem('Info'));
        return currentUser[name];
    }

    deleteCookie() {
        this.cookie.deleteCookie('_Kumorai-RefreshToken');
        this.cookie.deleteCookie('_Kumorai-ExpireToken');
        this.cookie.deleteCookie('_Kumorai-ExpireNewToken');
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        return throwError(error);
    }

    logout(isRevokeToken: boolean = false) {
        let token = this.cookie.getCookie('_Kumorai-RefreshToken');
        if (token && isRevokeToken) {
            this.revokeToken(token).subscribe((user) => {});
        }
        this.deleteCookie();
        localStorage.clear();
        sessionStorage.removeItem('ActiveTab');
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.redirectUrl } });
        this.clearAllTimer();
    }

    clearAllTimer() {
        this.dialog.closeAll();
        clearInterval(this.userActivity);
    }

    checkUserInactivity() {
        this.userActivity = setInterval(() => {
            let IdleTime = localStorage.getItem('IdleTime')
                ? Number(localStorage.getItem('IdleTime'))
                : 0;
            let currentTime = new Date().getTime();
            let diffTime = (currentTime - IdleTime) / 1000;
            if (
                ((IdleTime > 0 && diffTime > Number(localStorage.getItem('SessionTime')) - 30) ||
                    localStorage.getItem('sessionConfirmation')) &&
                this.isLoggedIn()
            ) {
                this.confirmation();
            }
            let seconds = this.getTokenExipryTimeLeft('_Kumorai-ExpireToken');
            if (seconds < 30 && (!localStorage.getItem('IsRefreshTokenCall') || localStorage.getItem('IsRefreshTokenCall') == 'false')) {
                this.refreshToken();
                return;
            }
            seconds = this.getTokenExipryTimeLeft('_Kumorai-ExpireNewToken');
            if (seconds < 5 && (!localStorage.getItem('IsRefreshTokenCall') || localStorage.getItem('IsRefreshTokenCall') == 'false')) {
                this.refreshToken();
            }
        }, 3000);
    }

    confirmation() {
        this.dialogRef = this.dialog.open(SessionDialogComponent, {
            panelClass: 'custom-dialog-container',
            disableClose: true
        });
        localStorage.setItem('sessionConfirmation', 'true');
        this.dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
                localStorage.removeItem('sessionConfirmation');
                localStorage.setItem('IdleTime', new Date().getTime().toString());
                this.checkUserInactivity();
            } else {
                this.dialog.closeAll();
                this.isShowExpiryMessage = false;
                this.logout();
            }
        });
        clearInterval(this.userActivity);
    }

    refreshToken() {
        localStorage.setItem('IsRefreshTokenCall', 'true');
        this.getRefreshToken().subscribe(
            (user) => {
                localStorage.setItem('IsRefreshTokenCall', 'false');
                if (user) {
                    localStorage.setItem('token', user['Token']);
                    this.storeRefreshToken(user['RefreshToken']);
                } else {
                    this.afterSessionExpired();
                }
            },
            (error) => {
                this.afterSessionExpired();
            }
        );
    }

    afterSessionExpired() {
        this.toastrService.info("Unable to fetch token. Please Login again");
        this.logout();
    }

    getTokenExipryTimeLeft(key) {
        let seconds = 0;
        let expireToken = this.cookie.getCookie(key);
        if (expireToken) {
            let date = new Date();
            let currentTime = date.getTime();
            let UserActiveTime = new Date(expireToken).getTime();
            let difference_In_Time = UserActiveTime - Number(currentTime);
            seconds = difference_In_Time / 1000;
        }
        return seconds;
    }
}
